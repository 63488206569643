<template>
  <div class="home">
    <!-- <div class="row">
      <div class="col-md-7">
        <div class="card bg-dark p-4">
          <LineChartComponent v-if="reports.orders_daily" :chart-data="reports.orders_daily" :options="options"></LineChartComponent>
        </div>
      </div>
      <div v-if="reports.financial" class="col-md-5">
        <div class="card bg-dark p-4">
          <p class="text-muted">Valor Vendido: {{ reports.financial.sold | money }}</p>
          <p class="text-muted">Valor Autorizado: {{ reports.financial.authorized | money }}</p>
          <p class="text-muted">Aguardando Pagamento: {{ reports.financial.waiting | money }}</p>
          <p class="text-muted">Valor Recusado: {{ reports.financial.refused | money }}</p>
          <p class="text-muted">Valor Cancelado: {{ reports.financial.cancelled | money }}</p>
          <p class="text-muted">Ticket Médio: {{ reports.financial.average | money }}</p>
        </div>
      </div>
    </div>
    <DoughnutChartComponent v-if="reports.regions" :chart-data="reports.regions"></DoughnutChartComponent> -->
    <div class="card mb-4">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <p class="mb-0 fs-16 fw-600">Dashboard</p>
          </div>
          <div class="col-8 d-flex align-items-center justify-content-end">
            <svg @click="openDashFilterModal()" class="pointer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1 -1 20 20" height="20" width="20" id="Filter-2--Streamline-Core.svg"><g id="Filter-2--Streamline-Core.svg"><path id="Vector" stroke="#92929d" stroke-linecap="round" stroke-linejoin="round" d="M17.357142857142858 0.6428571428571429H0.6428571428571429l6.428571428571429 9v7.714285714285715l3.8571428571428577 -2.5714285714285716v-5.142857142857143l6.428571428571429 -9Z" stroke-width="2"></path></g></svg>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9">
        <div class="card mb-4">
          <p class="fs-16 fw-600 mb-0 pb-3">Total de pedidos</p>
          <LineChartComponent v-if="reports.orders_daily" :chart-data="reports.orders_daily" :options="options"></LineChartComponent>
        </div>
        <div class="card mb-4">
          <p class="fs-16 fw-600 mb-0 pb-3">Últimos Pedidos</p>
          <div class="table-responsive mt-1">
            <table class="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nome</th>
                  <th>Horário</th>
                  <th>Cidade-UF</th>
                  <th>Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="order in reports.orders" :key="order.id">
                  <td class="align-middle">
                    <router-link class="body-table c-black" :to="'./orders/' + order.id">
                      {{ order.id }}
                    </router-link>
                  </td>
                  <td class="align-middle">
                    <router-link class="body-table c-black" :to="'./orders/' + order.id">
                      {{ order.name.substr(0, 20)}}...
                    </router-link>
                  </td>
                  <td>
                    {{ order.created_at | datetime }}
                  </td>
                  <td class="align-middle">
                      {{ order.city }} - {{ order.state }}
                  </td>
                  <td>
                    {{ order.amount | money }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card mb-4">
          <p class="fs-16 fw-600 mb-0 pb-3">Erros de Integração</p>
          <div class="table-responsive mt-1">
            <table class="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Integração</th>
                  <th>Pedido</th>
                  <th>Status</th>
                  <th>Atualização</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="order_integration in reports.integrations.errors" :key="order_integration.id">
                  <td class="align-middle">
                    {{ order_integration.id }}
                  </td>
                  <td class="align-middle">
                    {{ order_integration.company_integration.integration.description }}
                  </td>
                  <td class="align-middle">
                    <router-link class="body-table c-black" :to="'./orders/' + order_integration.company_order_id">
                      {{ order_integration.company_order_id }}
                    </router-link>
                  </td>
                  <td>
                    Erro
                    <!-- <button class="btn mr-2 " data-toggle="modal" @click="openUpdateModal(integration)">
                      <i class="far fa-edit"></i>
                    </button>
                    <button class="btn" data-toggle="modal" @click="openDeleteModal(integration)">
                      <i class="fas fa-times text-danger"></i>
                    </button> -->
                  </td>
                  <td>
                    {{ order_integration.updated_at | datetime }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card mb-4">
          <p class="fs-16 fw-600 mb-0 pb-3">Valor vendido</p>
          <div class="d-flex align-items-center">
            <p class="fs-28 fw-600 mb-0 mr-3">{{ reports.financial.sold | money }}</p>
            <!-- <p class="fs-16 fw-600 mb-0 c-green">+2.5%
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.9965 8.58307e-06C5.81079 -0.00090313 5.62477 0.0751123 5.49117 0.228018L0.831152 5.56135C0.588893 5.83861 0.617269 6.25977 0.894531 6.50203C1.17179 6.74429 1.59295 6.71591 1.83521 6.43865L5.33318 2.43526L5.33318 11.3333C5.33318 11.7015 5.63166 12 5.99985 12C6.36804 12 6.66651 11.7015 6.66651 11.3333L6.66651 2.44665L10.1651 6.43936C10.4078 6.71628 10.8289 6.74406 11.1059 6.50141C11.3828 6.25876 11.4106 5.83756 11.1679 5.56064L6.55155 0.292299C6.43161 0.11588 6.22926 0 5.99985 0C5.99873 0 5.99762 2.86102e-06 5.9965 8.58307e-06Z" fill="#3DD598"/>
              </svg>
            </p> -->
          </div>
          <!-- <p class="fs-14 fw-400 mb-0 c-grey">Comparação com o ano anterior</p> -->
        </div>
        <div class="card mb-4">
          <p class="fs-16 fw-600 mb-0 pb-3">Valor autorizado</p>
          <div class="d-flex align-items-center">
            <p class="fs-28 fw-600 mb-0 mr-3">{{ reports.financial.authorized | money }}</p>
            <!-- <p class="fs-16 fw-600 mb-0 c-green">+0.5%
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.9965 8.58307e-06C5.81079 -0.00090313 5.62477 0.0751123 5.49117 0.228018L0.831152 5.56135C0.588893 5.83861 0.617269 6.25977 0.894531 6.50203C1.17179 6.74429 1.59295 6.71591 1.83521 6.43865L5.33318 2.43526L5.33318 11.3333C5.33318 11.7015 5.63166 12 5.99985 12C6.36804 12 6.66651 11.7015 6.66651 11.3333L6.66651 2.44665L10.1651 6.43936C10.4078 6.71628 10.8289 6.74406 11.1059 6.50141C11.3828 6.25876 11.4106 5.83756 11.1679 5.56064L6.55155 0.292299C6.43161 0.11588 6.22926 0 5.99985 0C5.99873 0 5.99762 2.86102e-06 5.9965 8.58307e-06Z" fill="#3DD598"/>
              </svg>
            </p> -->
          </div>
          <!-- <p class="fs-14 fw-400 mb-0 c-grey">Comparação com o ano anterior</p> -->
        </div>
        <div class="card mb-4">
          <p class="fs-16 fw-600 mb-0 pb-3">Ticket médio</p>
          <div class="d-flex align-items-center">
            <p class="fs-28 fw-600 mb-0 mr-3">{{ reports.financial.average | money }}</p>
          </div>
          <!-- <p class="fs-14 fw-400 mb-0 c-grey">Comparação com o ano anterior</p> -->
        </div>
        <div class="card mb-4">
          <p class="fs-16 fw-600 mb-0 pb-3">Aguardando pagamento</p>
          <div class="d-flex align-items-center">
            <p class="fs-28 fw-600 mb-0 mr-3">{{ reports.financial.waiting | money }}</p>
            <!-- <p class="fs-16 fw-600 mb-0 c-danger">-1.5%
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.9965 8.58307e-06C5.81079 -0.00090313 5.62477 0.0751123 5.49117 0.228018L0.831152 5.56135C0.588893 5.83861 0.617269 6.25977 0.894531 6.50203C1.17179 6.74429 1.59295 6.71591 1.83521 6.43865L5.33318 2.43526L5.33318 11.3333C5.33318 11.7015 5.63166 12 5.99985 12C6.36804 12 6.66651 11.7015 6.66651 11.3333L6.66651 2.44665L10.1651 6.43936C10.4078 6.71628 10.8289 6.74406 11.1059 6.50141C11.3828 6.25876 11.4106 5.83756 11.1679 5.56064L6.55155 0.292299C6.43161 0.11588 6.22926 0 5.99985 0C5.99873 0 5.99762 2.86102e-06 5.9965 8.58307e-06Z" fill="#3DD598"/>
              </svg>
            </p> -->
          </div>
          <!-- <p class="fs-14 fw-400 mb-0 c-grey">Comparação com o ano anterior</p> -->
        </div>
        <div class="card mb-4">
          <p class="fs-16 fw-600 mb-0 pb-3">Valor recusado</p>
          <div class="d-flex align-items-center">
            <p class="fs-28 fw-600 mb-0 mr-3">{{ reports.financial.refused | money }}</p>
            <!-- <p class="fs-16 fw-600 mb-0 c-green">+2.5%
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.9965 8.58307e-06C5.81079 -0.00090313 5.62477 0.0751123 5.49117 0.228018L0.831152 5.56135C0.588893 5.83861 0.617269 6.25977 0.894531 6.50203C1.17179 6.74429 1.59295 6.71591 1.83521 6.43865L5.33318 2.43526L5.33318 11.3333C5.33318 11.7015 5.63166 12 5.99985 12C6.36804 12 6.66651 11.7015 6.66651 11.3333L6.66651 2.44665L10.1651 6.43936C10.4078 6.71628 10.8289 6.74406 11.1059 6.50141C11.3828 6.25876 11.4106 5.83756 11.1679 5.56064L6.55155 0.292299C6.43161 0.11588 6.22926 0 5.99985 0C5.99873 0 5.99762 2.86102e-06 5.9965 8.58307e-06Z" fill="#3DD598"/>
              </svg>
            </p> -->
          </div>
          <!-- <p class="fs-14 fw-400 mb-0 c-grey">Comparação com o ano anterior</p> -->
        </div>
        <div class="card mb-4">
          <p class="fs-16 fw-600 mb-0 pb-3">Valor cancelado</p>
          <div class="d-flex align-items-center">
            <p class="fs-28 fw-600 mb-0 mr-3">{{ reports.financial.cancelled | money }}</p>
          </div>
          <!-- <p class="fs-14 fw-400 mb-0 c-grey">Comparação com o ano anterior</p> -->
        </div>
      </div>
    </div>
    <div class="modal fade mt-5" id="openDashboardFilterModal" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog " role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Selecione o intervalo de data</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" >
            <div class="row">
              <div class="col">
                <label for="from" class="form-control-label">De:</label>
                <input type="date" id="from" v-model="reports.from" class="form-control">
              </div>
              <div class="col">
                <label for="to" class="form-control-label">Até</label>
                <input type="date" id="to" v-model="reports.to" class="form-control">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Fechar</button>
            <button class="btn btn-secondary px-4" @click="index({ types: ['orders-daily', 'orders-financial', 'integrations-errors', 'last-order'], from: reports.from, to: reports.to })">Buscar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
// import OrderComponent from '../components/layout/BarChart.vue'
// import DoughnutChartComponent from './chart/DoughnutChartComponent'
import LineChartComponent from './chart/LineChartComponent'

export default {
  name: 'Home',
  components: {
    // DoughnutChartComponent,
    LineChartComponent
    // OrderComponent
  },

  computed: mapState({
    reports: state => state.reports.data
  }),

  data () {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false
      },
      from: null,
      to: null
    }
  },

  methods: {
    ...mapActions('reports', [
      'index'
    ]),

    getRandomInt () {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    },

    openDashFilterModal () {
      $('#openDashboardFilterModal').modal('show')
    }
  },

  mounted () {
    this.index({
      types: ['orders-daily', 'orders-financial', 'integrations-errors', 'last-order'],
      from: null,
      to: null
    })
  }

}
</script>
